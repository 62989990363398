"use client";
import { React, useEffect, useRef, useState } from "react";
import HomeDesktop from "./HomeDesktop";
import HomeMobile from "./HomeMobile";

const Home = () => {
    const [width, setWidth] = useState(window?.innerWidth);
    const breakpoint = 811;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window?.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        // Return a function from the effect that removes the event listener
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return width < breakpoint ? <HomeMobile /> : <HomeDesktop />;
};

export default Home;
