"use client";

import { ReactNode } from "react";

import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Image,
    useColorModeValue,
} from "@chakra-ui/react";

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
            {children}
        </Text>
    );
};

const Footer = () => {
    return (
        <Box
            bg={"brand.blue"}
            color={useColorModeValue("gray.700", "gray.200")}
        >
            <Container color={"brand.cream"} as={Stack} maxW={"6xl"} py={10}>
                <SimpleGrid
                    templateColumns={{
                        sm: "1fr 1fr",
                        md: "5fr 1fr",
                    }}
                    spacing={8}
                >
                    <Stack spacing={6}>
                        <Box>
                            <Image
                                src="/LOGO_cream_small.png"
                                height={"100px"}
                            />
                        </Box>

                        <Text fontSize={"sm"}>
                            © 2024 CCEL. All rights reserved
                        </Text>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>Follow Us</ListHeader>
                        <Box as="a" href={"https://x.com/CCELorg"}>
                            Twitter
                        </Box>
                        <Box
                            as="a"
                            href={
                                " https://bsky.app/profile/ccelorg.bsky.social"
                            }
                        >
                            Bluesky
                        </Box>
                        <Box as="a" href={"https://www.instagram.com/ccelorg/"}>
                            Instagram
                        </Box>
                        <Box as="a" href={"https://www.tiktok.com/@CCELorg"}>
                            TikTok
                        </Box>
                        <Box
                            as="a"
                            href={"https://www.linkedin.com/in/ccelorg/"}
                        >
                            Linkedin
                        </Box>
                        <Box as="a" href={" https://www.twitch.tv/ccelorg"}>
                            Twitch
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default Footer;
