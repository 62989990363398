"use client";
import { React, useEffect, useRef, useState } from "react";
// import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Image,
    Text,
    Heading,
    Container,
    Spacer,
    Button,
    Center,
    AspectRatio,
    SimpleGrid,
    Link,
    useClipboard,
    Input,
    UnorderedList,
    ListItem,
    Stack,
    Card,
    CardHeader,
    CardBody,
} from "@chakra-ui/react";
import { SkipNavLink, SkipNavContent } from "@chakra-ui/skip-nav";
import { Link as RouterLink } from "react-router-dom";
import styles from "./home.css";

const HomeMobile = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState("contact@californiaesports.org");
    const { onCopy, hasCopied } = useClipboard(value);
    const homeRef = useRef(null);
    const joinRef = useRef(null);
    const aboutRef = useRef(null);
    const gamesRef = useRef(null);

    return (
        <Box alignItems="center">
            <Box id="homeElement" ref={homeRef}>
                <Container maxW="6xl" mb={10} alignItems="center">
                    <Center>
                        <Stack>
                            <Box>
                                <Image
                                    maxH={"300px"}
                                    src="/LOGO_blue_small.png"
                                />
                            </Box>
                            <Center>
                                <Box
                                    textAlign={["center"]}
                                    color={"brand.blue"}
                                    maxW="250px"
                                >
                                    <Heading size="3xl" pb="10px">
                                        California
                                        <br />
                                        Collegiate
                                        <br />
                                        Esports
                                        <br />
                                        League
                                    </Heading>
                                    <Heading pt="3" size="md">
                                        Non-profit competitive Esports events
                                        and programs for colleges across
                                        California
                                    </Heading>
                                </Box>
                            </Center>
                            <Center pb="160px" pt="20px">
                                <Button
                                    // as={"a"}
                                    // href="/participate"
                                    size="3xl"
                                    height="68px"
                                    width="250px"
                                    border="2px"
                                    borderColor={"brand.lightBlue"}
                                    bgColor={"brand.blue"}
                                    color={"brand.white"}
                                    onClick={() => {
                                        joinRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                        });
                                    }}
                                >
                                    <Heading
                                        size="md"
                                        textColor={"brand.cream"}
                                    >
                                        Join our League
                                    </Heading>
                                </Button>
                            </Center>
                        </Stack>
                    </Center>
                </Container>
            </Box>
            <Box
                backgroundColor={"brand.lightBlue"}
                pt="40px"
                id="aboutSection"
                alignItems="center"
                ref={aboutRef}
            >
                <Container maxW="6xl" textColor={"brand.cream"}>
                    <Heading size="2xl" textAlign={"center"}>
                        Our Mission
                    </Heading>
                    <Box pt="30px" pb="40px">
                        Driven by esports competitors, college educators, and
                        industry professionals, CCEL focuses on providing
                        end-to-end support for collegiate esports organizations
                        and events - from a program’s Day 1 to their 1st
                        tournament victory. Our mission is to partner with
                        schools and organizations to make their esports programs
                        successful. Whether your school is just beginning its
                        esports journey or you’re an experienced esports
                        organization looking to further your program, CCEL can
                        provide resources and expertise that helps your players
                        flourish. Education is the heart of what we do. Our core
                        values and mission revolve around the unification and
                        integration of education and esports, because the
                        students and schools that compete are the lifeblood of
                        collegiate esports and CCEL. We believe that it is
                        paramount to provide all student-competitors an
                        environment in which they can thrive - both academically
                        and competitively. CCEL’s goal is to help every school
                        and organization provide the education and training
                        necessary for students to succeed.
                    </Box>
                </Container>
            </Box>
            <Box
                backgroundColor={"brand.lightBlue"}
                alignItems="center"
                pt="30px"
            >
                <Container maxW="6xl">
                    <Heading
                        pt="30px"
                        textAlign={"center"}
                        textColor={"brand.cream"}
                        size="2xl"
                    >
                        Our Team
                    </Heading>
                    <Center>
                        <Stack pt="50px" pb="50px">
                            <Card
                                w="250px"
                                backgroundColor={"brand.cream"}
                                m="20px"
                            >
                                <CardHeader>
                                    <Center>
                                        <Image
                                            borderRadius="full"
                                            boxSize="150px"
                                            src="/r.jpg"
                                            alt="Roshan"
                                        />
                                    </Center>
                                </CardHeader>
                                <CardBody>
                                    <Text
                                        textColor={"brand.blue"}
                                        textAlign={"center"}
                                    >
                                        Roshan Uma - Executive Director
                                    </Text>
                                </CardBody>
                            </Card>
                            <Card
                                w="250px"
                                backgroundColor={"brand.cream"}
                                m="20px"
                            >
                                <CardHeader>
                                    <Center>
                                        <Image
                                            borderRadius="full"
                                            boxSize="150px"
                                            src="/t.png"
                                            alt="Travis"
                                        />
                                    </Center>
                                </CardHeader>
                                <CardBody>
                                    <Text
                                        textColor={"brand.blue"}
                                        textAlign={"center"}
                                    >
                                        Travis Osborn - Director of Operations{" "}
                                    </Text>
                                </CardBody>
                            </Card>
                            {/*}
                                    <Card
                                        w="250px"
                                        backgroundColor={"brand.cream"}
                                        m="20px"
                                    >
                                        <CardHeader>
                                            <Center>
                                                <Image
                                                    borderRadius="full"
                                                    boxSize="150px"
                                                    src="/a.jpg"
                                                    alt="Austin"
                                                />
                                            </Center>
                                        </CardHeader>
                                        <CardBody>
                                            <Text textAlign={"center"}>
                                                Haily Beazly - Director of Marketing and
                                                Communications{" "}
                                            </Text>
                                        </CardBody>
                                    </Card>
                                    { */}
                            <Card
                                w="250px"
                                backgroundColor={"brand.cream"}
                                m="20px"
                            >
                                <CardHeader>
                                    <Center>
                                        <Image
                                            borderRadius="full"
                                            boxSize="150px"
                                            src="/a.jpg"
                                            alt="Austin"
                                        />
                                    </Center>
                                </CardHeader>
                                <CardBody>
                                    <Text
                                        textColor={"brand.blue"}
                                        textAlign={"center"}
                                    >
                                        Austin Martinez - Director of
                                        Technologies
                                    </Text>
                                </CardBody>
                            </Card>
                        </Stack>
                    </Center>
                </Container>
            </Box>
            <Box
                backgroundColor={"brand.lightBlue"}
                alignItems="center"
                pt="30px"
            >
                <Container maxW="6xl">
                    <Heading
                        pt="30px"
                        textAlign={"center"}
                        textColor={"brand.cream"}
                        size="2xl"
                    >
                        Our Story
                    </Heading>
                    <Stack pt="50px" pb="50px">
                        <Center>
                            <Box>
                                <Image
                                    maxHeight={"400px"}
                                    borderRadius={"10px"}
                                    src="/logo photo blue.JPG"
                                />
                            </Box>
                        </Center>
                        <Box p="30px" textColor={"brand.cream"}>
                            <Text>
                                For more than a decade, we’ve had a passion for
                                both collegiate & professional esports which
                                began when we, too, were students. After
                                graduating from various community colleges,
                                state universities, and higher education
                                programs, we pursued roles within esports and
                                related fields - game development, education,
                                event production, marketing, etc - eventually
                                assuming key leadership roles in our
                                organizations and our fields. Through our
                                professional experience, we gained firsthand
                                insight into the unique challenges faced by
                                esports organizations, their industry, and their
                                communities. Our primary founder, Roshan Uma,
                                recognized the possibilities that could result
                                from greater support within the world of
                                esports, especially on college campuses. He
                                experienced the benefits of pairing a student’s
                                passion for esports with the value of education
                                when he founded collegiate esports programs at
                                both Norco College and Santiago Canyon College
                                in southern California. During his tenure at
                                both programs, it became clear that two things
                                would supercharge collegiate esports: stronger
                                connections between competitive esports
                                organizations around California and more
                                opportunities provided to students with an
                                interest in esports, specifically a clearer
                                educational-to-professional pathway. This vision
                                led to the creation of the California Collegiate
                                Esports League (CCEL) – a nonprofit dedicated to
                                partnering with educational institutions and
                                fostering competitive opportunities to bridge
                                the gap between education and esports.
                            </Text>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box bgColor={"brand.green"}>
                <Container maxW="6xl">
                    <Stack>
                        <Image
                            maxHeight={"400px"}
                            borderRadius={"10px"}
                            src="/crowd.JPG"
                            pt="30px"
                        />
                        <Box p="30px" textColor={"brand.cream"}>
                            <Heading>Esports Events and Tournaments</Heading>
                            <Text pt="10px">
                                Backed by more than a decade of experience in
                                creating, coordinating, and operating events,
                                CCEL gets involved by helping member
                                organizations and schools run tournaments and
                                events for their esports programs. We can help
                                your organization too! CCEL is currently
                                building the CCEL Founder’s League in Southern
                                California - the first competitive conference of
                                schools and organizations partnered with CCEL!
                                Interested in becoming a founder? Get in touch
                                with us!
                            </Text>
                        </Box>
                        <Box p="30px" textColor={"brand.cream"}>
                            <Heading>Supporting YOUR Esports Programs</Heading>
                            <Text pt="10px">
                                Looking to start or improve your organization’s
                                esports program? CCEL can help! We can partner
                                with your org to provide expert guidance, staff
                                support, paths to hardware acquisition, and
                                more.
                            </Text>
                        </Box>
                        <Image
                            maxHeight={"400px"}
                            borderRadius={"10px"}
                            src="/computers.JPG"
                            pb="40px"
                        />
                    </Stack>
                </Container>
            </Box>

            <Box
                id="joinSection"
                alignItems="center"
                textAlign={"center"}
                ref={joinRef}
            >
                <Container maxW="6xl">
                    <Box pt="50px">
                        <SkipNavContent />
                        <Heading textColor={"brand.blue"} size="2xl">
                            Partner with CCEL
                        </Heading>
                        <Center>
                            <Box pt="30px" pb="10px" textColor={"brand.blue"}>
                                Whether you’re a program manager, educator,
                                administrator, or a student, we encourage you to
                                reach out! We welcome the opportunity to have a
                                conversation about how collegiate esports can
                                benefit your students and your institution, and
                                how CCEL can help achieve that goal.
                            </Box>
                        </Center>
                        <Center>
                            <Box pt="30px" pb="10px" textColor={"brand.blue"}>
                                Feel free to email us at:
                            </Box>
                        </Center>
                        <Center>
                            <Flex w="350px" pb="30px" color={"brand.blue"}>
                                <Input
                                    value={value}
                                    isReadOnly
                                    placeholder="contact@californiaesports.org"
                                    variant="filled"
                                    backgroundColor={"brand.white"}
                                />
                                <Button
                                    borderColor={"brand.blue"}
                                    onClick={onCopy}
                                    ml={2}
                                    variant="outline"
                                >
                                    {hasCopied ? "Copied" : "Copy"}
                                </Button>
                            </Flex>
                        </Center>
                    </Box>
                </Container>
                <Container maxW="6xl" id="donateSection" pt="30px">
                    <Box pb="20px">
                        <Heading textColor={"brand.blue"} size="2xl">
                            Support CCEL
                        </Heading>
                    </Box>
                    <Box pt="30px" textColor={"brand.blue"} pb="40px">
                        As a nonprofit organization, CCEL exists due to the
                        support of individuals like you. Your support empowers
                        us to make a lasting impact in the esports community and
                        on college campuses around California. With your
                        support, we can support more programs, provide greater
                        resources, and create more opportunities for aspiring
                        players, mentors, and enthusiasts. Together, we can
                        build an inclusive and thriving esports environment that
                        fosters growth, skill development, and positive
                        community connections. Thank you for believing in our
                        mission and for helping student-competitors all across
                        California!
                    </Box>
                </Container>
                <Container maxW="6xl" pt="30px">
                    <Box pb="20px">
                        <Heading textColor={"brand.blue"} size="2xl">
                            Donate to CCEL
                        </Heading>
                    </Box>
                    <Box pt="30px" textColor={"brand.blue"} pb="40px">
                        Thank you for your interest in supporting CCEL! As a
                        newly established nonprofit organization, we are in the
                        process of setting up our official donation systems. In
                        the meantime, we welcome pledges of support, as well as
                        non-monetary contributions that can help us grow. Your
                        commitment to CCEL makes a meaningful difference in
                        establishing our vision for an inclusive and impactful
                        collegiate esports community. Thank you for being a part
                        of our journey!
                    </Box>
                    <Center>
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSe5POzbzOD7F5kzPutSLEHm1jHul9V6Y0D9jVmg8yrIE-HL4w/viewform?embedded=true"
                            width="640"
                            height="1100"
                            frameborder="0"
                            marginheight="0"
                            marginwidth="0"
                        >
                            Loading…
                        </iframe>
                    </Center>
                </Container>
            </Box>

            <Container textAlign="right" pb="5px" className="top" maxW="6xl">
                <Link
                    color={"brand.blue"}
                    borderRadius={"5px"}
                    p="5px"
                    bgColor={"brand.cream"}
                    onClick={() => {
                        // Different here because there is no navbar on mobile
                        const element = document.getElementById("homeElement");
                        element?.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Back to top
                </Link>
            </Container>
        </Box>
    );
};

export default HomeMobile;
