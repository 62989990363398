"use client";
import { React, useEffect, useRef, useState } from "react";
// import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Image,
    Text,
    Heading,
    Container,
    Spacer,
    Button,
    Center,
    AspectRatio,
    SimpleGrid,
    useClipboard,
    Input,
    UnorderedList,
    ListItem,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const Donate = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box>
                <Heading>This is the donate page</Heading>
            </Box>
        </>
    );
};

export default Donate;
