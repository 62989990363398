import React from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme, Box } from "@chakra-ui/react";
import "./App.css";

//Components
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Donate from "./components/Donate/Donate";

// Color theme
const colors = {
    brand: {
        blue: "#092049",
        cream: "#EDDCC0",
        white: "#FDFDFB",
        red: "#CA0B2C",
        green: "#47AC6A",
        lightBlue: "#163A78",
        lighterBlue: "#4D93D1",
        black: "#000",
        gray: "#C1CBD1",
        link: "#60E5F7",
        linkHover: "#49ADBA",
        highLight: "#51DACF",
        highLightHover: "#51DACF",
    },
};
const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
};
const theme = extendTheme({ colors, config });

const App = () => {
    return (
        <ChakraProvider theme={theme}>
            <Router>
                <Box bg="brand.cream" color="brand.white">
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/donate" element={<Donate />} />
                    </Routes>
                    <Footer />
                </Box>
            </Router>
        </ChakraProvider>
    );
};

export default App;
