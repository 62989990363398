"use client";
import { React, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Link,
    Button,
    Stack,
    useColorModeValue,
    useBreakpointValue,
    Center,
    Image,
    Container,
} from "@chakra-ui/react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

const Nav = () => {
    let [cookies, setCookie, removeCookie] = useCookies(["loggedIn"]);
    const navigate = useNavigate();
    return (
        <Box id="navbar">
            <Container maxW="6xl" mb={[0, 10]}>
                <Flex
                    bg={useColorModeValue("brand.cream")}
                    color={useColorModeValue("brand.white")}
                    minH={"60px"}
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    borderBottom={1}
                    align={"center"}
                >
                    <Flex
                        flex={{ base: 1, md: "auto" }}
                        ml={{ base: -2 }}
                        display={{ base: "flex", md: "none" }}
                    ></Flex>
                    <Flex
                        flex={{ base: 1 }}
                        justify={{ base: "center", md: "start" }}
                    >
                        <Flex display={{ base: "none", md: "flex" }}>
                            <Stack direction={"row"} spacing={4}>
                                {/*}
                                <Center>
                                    <Button
                                        as="a"
                                        href="/"
                                        color="brand.blue"
                                        fontWeight={700}
                                        backgroundColor={"brand.cream"}
                                        _hover={{
                                            background: "brand.lighterBlue",
                                        }}
                                    >
                                        Home
                                    </Button>
                                </Center>
                                { */}
                                <Center>
                                    <Button
                                        // as={RouterLink}
                                        // to="/about"
                                        color="brand.blue"
                                        fontWeight={700}
                                        backgroundColor={"brand.cream"}
                                        _hover={{
                                            background: "brand.lighterBlue",
                                        }}
                                        onClick={() => {
                                            const element =
                                                document.getElementById(
                                                    "aboutSection",
                                                );
                                            element?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                        }}
                                    >
                                        About
                                    </Button>
                                </Center>
                                <Center>
                                    <Button
                                        // as={RouterLink}
                                        // to="/participate"
                                        color="brand.blue"
                                        fontWeight={700}
                                        backgroundColor={"brand.cream"}
                                        _hover={{
                                            background: "brand.lighterBlue",
                                        }}
                                        onClick={() => {
                                            const element =
                                                document.getElementById(
                                                    "joinSection",
                                                );
                                            element?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                        }}
                                    >
                                        Partner
                                    </Button>
                                </Center>
                                <Center>
                                    <Button
                                        // as={RouterLink}
                                        // to="/donate"
                                        color="brand.blue"
                                        fontWeight={700}
                                        backgroundColor={"brand.cream"}
                                        _hover={{
                                            background: "brand.lighterBlue",
                                        }}
                                        onClick={() => {
                                            const element =
                                                document.getElementById(
                                                    "donateSection",
                                                );
                                            element?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                        }}
                                    >
                                        Support
                                    </Button>
                                </Center>
                                {cookies.loggedIn ? (
                                    <Center>
                                        <Link
                                            as={RouterLink}
                                            to="/dashboard"
                                            color="brand.blue"
                                            fontWeight={700}
                                        >
                                            Dashboard
                                        </Link>
                                    </Center>
                                ) : (
                                    <div></div>
                                )}
                            </Stack>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
};
const NavBar = () => {
    const [width, setWidth] = useState(window?.innerWidth);
    const breakpoint = 811;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window?.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        // Return a function from the effect that removes the event listener
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return width < breakpoint ? <div></div> : <Nav />;
};
// <Button onClick={() => clearCookies()}>Remove Cookies</Button>

export default NavBar;
